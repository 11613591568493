@font-face{    
  font-family:'SukhumvitSet-Text';    
  src:url('../fonts/SukhumvitSet-Text.ttf')format('truetype');    
  font-weight: normal;    
  font-style: normal;
}

.terms-and-privacy-page {
  font-family: 'SukhumvitSet-Text' !important;
}

body {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: #F3F3F3;
  font-family: 'SukhumvitSet-Text' !important;
  font-style: normal !important;
  font-weight: 600 !important;
}

.hr-title{
  border: 0;
    border-top: 1px solid #e5e5ea;
    border-top: 1px solid var(--rs-border-primary);
    margin-bottom: 10px;
    margin-top: 20px;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
    margin: 2rem 0;
    color: inherit;
    opacity: .50;
    display: block;
    unicode-bidi: isolate;
    margin-block-start: 0.5em;
    margin-block-end: 1.5em;
    margin-inline-start: auto;
    margin-inline-end: auto;
    overflow: hidden;
}

:root {
  /* theme color ที่ใช้ในเว็บไซต์ */
  --color-main-theme: rgb(253, 144, 38);
  /* theme สีเทาเข้ม ที่ใช้ในเว็บไซต์ */
  --dark-gray-color: #4A4C51;
  /* theme สีเทาอ่อน ที่ใช้ในเว็บไซต์ */
  --light-gray-color: #9B9DA4;
}

.pdf-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* กำหนดความสูงเท่ากับขนาดหน้าจอ */
  text-align: center;
}

.pdf-show {
  max-width: 100%; /* กำหนดความกว้างสูงสุด */
  max-height: 100%; /* กำหนดความสูงสูงสุด */
}

.star {
  color: #ED1C24;
  margin-left: 5px;
}

.image-frame {
  border: 2px solid #ccc;
  border-radius: 5px;
  display: inline-block;
}

.hide-label .MuiInputBase-input::before {
  content: none;
}


.trigger-autocomplete-button {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: inline-flex;
  align-items: center;
  /* padding: 0.5rem 1rem; */
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.autocomplete-button-text {
  flex-grow: 1;
}

.trigger-autocomplete-button:active {
  background-color: #e0e0e0;
}

.reorder-datatable {
  border-collapse: collapse;
  width: 100%;
}

.reorder-datatable td {
  border: 1px solid #ffffff;
  padding: 8px;
  background-color: #ffffff; /* กำหนดสีพื้นหลังให้กับทุกแถว */
  color: #333;
}

.reorder-datatable .content-center {
  text-align: center;
}

.reorder-datatable th {
   
  padding: 8px;
  border: 1px solid #ffffff;
  background-color: #f2f2f2;
  text-align: center;
  padding-top: 12px;
  padding-bottom: 12px;
  color: #333;
}

.reorder-datatable-column{
  width: 3rem;
  height: 50px;
}

.reorder-datatable tr:nth-child(even) {
  background-color: #f2f2f2;
}

.reorder-datatable tr:hover {
  background-color: #f2f2f2;
}

.AutocompleteInput {
  padding-top: 10px;
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: 0;
  padding-left:0;
  margin-right: auto;
  margin-left: auto;
}

.form-check-input:checked {
  background-color: var(--color-main-theme);
  /* เปลี่ยนสี marker เมื่อ radio ถูกเลือก */
  border-color: var(--color-main-theme);
  ;
  /* เปลี่ยนสีขอบของ radio เมื่อ radio ถูกเลือก */
}

.button-delete-with-separator{
  display: inline;
}

.div-my-button {
  padding-top: 5px;
}

.font-tags {
  padding-left: 5px;
  padding-right: 5px;
}

.my-LabelTags {
  display: inline-block;
  padding: 2px 5px;
  background-color: rgb(59, 186, 138);
  color: rgb(229, 251, 245);
  text-decoration: none;
  border-radius: 20px;
  font-size: 16px;
  font-weight: bold;
}

.my-LabelTags:hover {
  background-color: rgb(59, 186, 138);
  color: rgb(229, 251, 245);
  text-decoration: none;
}

.my-LabelKeywords {
  display: inline-block;
  padding: 2px 5px;
  background-color: rgb(13, 129, 231);
  color: rgb(234, 245, 255);
  text-decoration: none;
  border-radius: 20px;
  font-size: 16px;
  font-weight: bold;
}


.my-LabelKeywords:hover {
  background-color: rgb(13, 129, 231);
  color: rgb(234, 245, 255);
  text-decoration: none;
}

.my-button {
  display: inline-block;
  padding: 4px 4px;
  background-color: #abaeb2;
  color: #fff;
  text-decoration: none;
  border-radius: 25px;
  font-size: 12px;
  cursor: pointer;
  font-weight: bold;
}

.my-button:hover {
  background-color: #ff9d58;
}

.my-button-keyboard {
  display: inline-block;
  padding: 4px 4px;
  background-color: #abaeb2;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
  font-weight: bold;
}

.icon-nav {
  width: 35px;
  height: 25px;
  padding-right: 10px;
  filter: brightness(1) invert(0);
}

.icon-PDF {
    /* width: 40px; */
    height: 30px;
    /* padding-left: 10px; */
    margin-right: 5px;
}

.str_icon {
  padding-right: 4px;
}

.vertical-line {
  border-left: 1px solid black;
}

.vertical-line2 {
  border-left: 1px solid black;
  height: 100px;
  position: absolute;
  left: 50%;
  margin-left: -15px;
  margin-top: 20px;
  padding-right: 40px;
}

.link-button-pading{
  padding-right: 10px;
  padding-left: 10px;
}

.link-button {
  font-size: 15px;
  line-height: 24px;
  /* padding-right: 10px;
  padding-left: 10px; */
  cursor: pointer;
  text-decoration-line: underline;
}

.normal-link {
  color: var(--color-main-theme);
}

.delete-link {
  color: #ED1C24;
}

.button-main{
  cursor: pointer;
  border-radius: 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 95px;
  font-weight: bold !important;
  padding: 1px;
}

.button-orange{
  background: var(--color-main-theme);
  background-color: var(--color-main-theme);
  border: none;
  color: white;
}

.button-orange:disabled,
.button-orange[disabled]{
  background-color: #cccccc;
  color: #666666;
}

.button-white:disabled,
.button-white[disabled]{
  background-color: #cccccc;
  color: #666666;
  border-color: #cccccc; 
}


.button-white{
  background: transparent;
  background-color: transparent;
  border: 2px solid var(--color-main-theme);;
  color: var(--color-main-theme);
}

.button-form-save {
  font-weight: bold !important;
  font-size: 19px;
  width: 190px;
  height: 44px;
  padding: 1px;
  margin: 4px 2px;
}

.button-form-cancel {
  width: 190px;
  height: 44px;
  font-size: 19px;
  margin: 4px 2px;
  margin-right: 50px;
}

.button-action {
  width: 220px;
  height: 40px;
  font-size: 16px;
  line-height: 25px;
}

.button-search{
  width: 100px;
  height: 40px;
  font-size: 16px;
  line-height: 25px;
}




.button-signup{
  font-weight: bold !important;
  font-size: 19px;
  width: 190px;
  height: 44px;
}

.boyd-div {
  padding-top: 40px;
}

.search-form {
  float: right;
  margin: 25 0px;
}

.tgs {
  margin: 1px;
  background: #cfc;
  border: 1px solid #aaa;
  padding: 0 3;
  font-size: 8pt;
}

.tgs:hover {
  background: #ccf;
}

label {
  font-size: 8pt;
  margin-top: 5px;
  margin-bottom: 0;
}

span.label {
  margin-top: 0px;
  padding: 5px 15px;
}

span.label input {
  position: relative;
  top: 3px;
}

.date {
  width: 120px;
  text-align: center;
}

.datetime {
  width: 200px;
}

.year {
  width: 60px;
  text-align: center;
}

thead tr {
  background: #444;
  color: #fff;
  text-align: center;
}

thead td {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 8pt !important;
  color: #fff;
}

.sum,
.int,
.inthead,
.real,
.views,
.users,
.no,
.id,
.EPs,
.amount,
.p_views,
.total,
.time,
.no-demical {
  text-align: center;
}

.publish,
.draft {
  text-align: center;
}

tr.odd {
  background: #eeeeff;
}

canvas {
  background: #fff;
}

.name_th {
  white-space: nowrap;
}

.fa-check-circle {
  color: green;
}

.rptcard {
  background: #fff;
  border: 1px solid #aaa;
  border-radius: 10px;
  text-align: center;
  display: inline-block;
  padding: 5px 10px;
  margin: 3px 5px;
}

.rptno {
  font-size: 32pt;
  color: #0a0;
  text-align: center;
}

.rptno.ais {
  color: #adc32b;
}

.media-file {
  background: #eef;
  margin: 2px;
}

.media-file a {
  color: #644;
  font-size: 9pt;
}

tr.hide {
  display: none;
}

.login-content button {
  background-color: var(--color-main-theme);
  border: none;
  color: white;
  padding: 1px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 19px;
  margin: 4px 2px;
  cursor: pointer;
  width: 100%;
  height: 50px;
  border-radius: 30px;
}

.login-content input {
  background-color: white;
  /* Green */
  border: 1px solid #CECECE;
  color: rgb(1, 0, 0);
  padding: 10px;
  text-align: left;
  text-decoration: none;
  display: inline-block;
  font-size: 19px;
  cursor: pointer;
  width: 100%;
  height: 50px;
  padding: 25px;
  border-radius: 30px;
}

a,
input,
h2,
h3,
h4,
h5,
h1,
button,
p {
  font-size: 19px;
  text-decoration: none;
}

.dashboard-card-content {
  margin-bottom: 30px;
}

/* Version in Report > Dashboard */
.dashboard-version {
  right: 10px;
  bottom: 0px;
  position: fixed;
}

/* End Version in Report > Dashboard */

/* -- TOP MENU -- */
/* paging control top menu */
.font-prev-next,
.font-prev-next:hover {
  color: inherit;
  text-decoration: none;
  font-size: 15px;
}

.font-prev-next-href {
  font-size: 15px;
}

.paging-control-top {
  float: right;
  clear: both;
  margin: -10 0 10px;
  width: 400px;
  text-align: right;
}

.paging-control-shelf-top {
  float: right;
  clear: both;
  margin: -10 0 -6px;
  width: 400px;
  text-align: right;
}

.paging-control-bottom {
  float: right;
  margin: -26 0 26px;
  width: 400px;
  text-align: right;
}

.paging-control-shelf-bottom {
  float: right;
  margin: -26 0 10px;
  width: 400px;
  text-align: right;
}

.forgotPassword {

  color: var(--color-main-theme);
  font-size: 16px;
  padding: "";
  text-decoration: underline;

}

.button-bold-text{
  font-weight: bold;
}

.login-option {
  color: #494949;
  font-size: 15px;
  white-space: nowrap;
  text-decoration: none;
  display: inline-block;
  margin-right: 8px;
 }
 
 .login-option-button {
  font-weight: bold;
  font-size: 16px;
  white-space: nowrap;
  color: var(--color-main-theme);
  text-decoration: underline;
  display: inline; 
 }

.string-Singn {
  font-size: 16px;
  white-space: nowrap;
  color: var(--color-main-theme);
  text-decoration: underline;
}

.Sign {
  position: absolute;
  color: black;
  font-size: 16px;
  left: 52%;
  white-space: nowrap;
  text-decoration: none;
}

.textEnter {
  color: rgb(161, 161, 161);
}

.button-back {
  display: inline-block;
  padding: 10px 20px;
  margin: 17px;
  border: 2px solid var(--color-main-theme);
  border-radius: 40px;
  background-color: #ffffff;
  color: var(--color-main-theme);
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  width: 30%;
  height: 50px;
}

.button-back:hover {
  background-color: var(--color-main-theme);
  color: #fff;
}



.Sign-Up {

  color: #494949;
  font-size: 16px;

  white-space: nowrap;
  text-decoration: none;
}

.string-Singn-Up {
  color: var(--color-main-theme);
  font-size: 16px;
  text-decoration: underline;
}

.button-pinn {
  padding: 10px 40px;
}

.hidden {
  display: none;
}


/* ส่วน Head */
.my-row-head {
  width: 101%;
  height: 100%;
  background: #FFFFFF;
}

.my-row {
  width: 101%;
  height: 80px;
  background: #FFFFFF;
}

.text-profile {
  font-weight: 50;
  font-size: 16px;
  line-height: 15px;

  /* align-items: center; */
  text-align: right;

  /* padding-right: 2%; */

  color: #686B73;
}


.div-Head-profile {
  padding-right: 10px;
}

.button-license :hover{
  background-color: blue !important;
}

.button-language {
  box-sizing: border-box;
  /* padding-top: 4px; */
  /* margin-top: 15px; */
  /* margin-right: 10px; */
  text-align: center;
  /* margin-bottom: 15px; */
  border: 2px solid var(--color-main-theme);
  border-radius: 30px;
  width: 100%;
  float: right;
  color: var(--color-main-theme);
  /* padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px; */
}

.button-language b {
  font-size: 18px;
  line-height: 29px;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  color: var(--color-main-theme);
}

.button-language::after {
  color: var(--color-main-theme);
}

.border {
  padding: 0;
  border: 1px solid #000000;
  transform: rotate(90deg);
}

.col-Profile-in img {
  padding-left: 10px;
}

.colin {
  width: 2%;
}

.col-LogOut-in {
  padding-top: 20px;
}

.App-logo1 {
  position: absolute;
  width: 115px;
  /* height: 44px; */
  left: 60px;
  top: 16px;
}

.text-Output {
  font-size: 16px;
  line-height: 25px;
  color: var(--color-main-theme);
}

/* =================== */

.my-row img {
  position: absolute;
  width: 115px;
  height: 44px;
  left: 60px;
  top: 16px;
}

.col-card {
  padding-left: 60px;
  padding-right: 60px;
}

.text-signuptosell {
  font-weight: 700;
  padding-top: 30px;
  padding-bottom: 10px;
  font-size: 32px;
  line-height: 51px;
  display: flex;
  align-items: center;
  color: #454A54;
}

.cardinfo{
  padding: 1.5rem 2rem;
}

.cardinfo Label {
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #454A54;
}

.card-status {
  margin-bottom: 30px;
  margin-top: 20px;
}

.card-box-status {
  --bs-card-spacer-x: 2rem;
}

.card-box-status-top-pading {
  --bs-card-spacer-y: 1rem;
}


.text-title {
  font-size: 18px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #454A54;
  margin-bottom: -1%;
}

.signup-page{
  background-color: #f3f3f3;
}

.signup-page textarea, 
.signup-page Select, 
.signup-page input[type="tel"],
.signup-page input[type="text"],
.signup-page input[type="email"],
.signup-page input[type="Number"] {
  width: 60%;
}

.div-signUp {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.div-signUp > * {
  margin-bottom: 10px; /* ปรับช่องว่างระหว่าง element */
}

.check-signup .form-check-label{
  font-weight: normal;
}

.check-signup .form-check-input {
  margin-top: 4px !important;
}

.text-titlefood {
  padding: 0px 0px 0px 220px;
  font-size: 17px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #454A54;
}

.adio-item input[type='radio'] {
  color: coral;
  accent-color: #232323;
}

.abutton {
  padding: 10px;
  font-size: 16px;
  white-space: nowrap;
  color: var(--color-main-theme);
  text-decoration: underline;
}

/* ส่วนของหน้า Navbr */
.row-navbar {
  margin: 0px 0px 0px -20px;
  height: 80%;
  background: #FFFFFF;
}

.col-siz {
  width: 18%;
  display: block;
}

.row-nav {
  list-style-type: none;
  padding: 0;
  width: 16%;
  background-color: #FFFFFF;
  position: fixed;
  height: 100%;
  overflow: auto;
}

.row-nav-link {
  padding-left: 30px;
  padding-top: 70px;
  width: 100%;
}

.link-text:hover {
  color: #807171;
}

.link-text {
  font-size: 16px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  color: #717580;
}

.text-menu {
  padding-left: 15px;
  padding-bottom: 20px;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #9e9ea2;
}


.row-context {
  background-color: #F3F3F3;
  width: 10%;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background: var(--color-main-theme);
  border-radius: 22px;

}

.nav-pills .nav-link.active .icon-nav {
  color: white !important;
  filter: brightness(0) invert(1);
}

/* ====================== */


/* ManagBook */
.alerts-text {
  padding-top: 12px;
  padding-left: 10px;
  padding-bottom: 10px;
  background: #C7C7C7;
  /* opacity: 0.3; */
  border-radius: 10px;

}

.box-alerts {
  padding-top: 26px;
  padding-left: 10px;
  padding-bottom: 18px;
  width: 56%;
}

.alerts-text {
  padding-top: 12px;
  padding-left: 12px;
  padding-bottom: 10px;

}

.text-title-alerts {
  font-size: 17px;
  line-height: 27px;
  display: flex;
  align-items: center;

  color: #454A54;

}

.text-alertsStar {
  font-size: 13px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #ff0303;
}



.text-alerts {
  font-size: 13px;
  line-height: 21px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  color: #686B73;
}

.box-context {
  padding-top: 1px;
  padding-left: 10px;
  padding-bottom: 18px;
  width: 100%;
}

.title-textReport {
  font-weight: 700;
  font-size: 24px;
  line-height: 38px;
  /* display: flex; */
  /* align-items: center; */
  color: #454A54;
  margin-top: 20px;
  margin-left: 10px; 
}

.title-text {
  font-weight: 700;
  font-size: 24px;
  line-height: 38px;
  display: flex;
  align-items: center;
  color: #454A54;
  margin-top: 20px;
  margin-left: 10px;
}

.inner-title-text{
  font-weight: 700;
  display: flex;
  align-items: center;
  color: #454A54;
  font-size: 19px;
}

.inner-title-detail{
  font-weight: 600;
  display: flex;
  align-items: center;
  color: #454A54;
  font-size: 17px;
}

.context-text1 {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  display: flex;
  align-items: center;
  font-style: bold;
  color: #686b73a8 !important;

}

.context-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  display: flex;
  align-items: center;
  font-style: bold;
  color: #686b73a8;
  margin-top: 0px;
  margin-bottom: 15px;
  margin-left: 10px;
}

.box-table {
  background: #FFFFFF;
  border-radius: 10px;
  width: 100%;
}

.thead-text th {
  color: #000000;
  background: #FFFFFF;
  margin-left: auto;
  margin-right: auto;

}

.vertical-line {
  width: 2px;
  height: 0px;
  opacity: 0.2;
  border: 1px solid #000000;
  transform: rotate(90deg);
  margin-top: 34px
}

.vertical-line2 {
  position: absolute;
  width: 40px;
  height: 0px;
  left: 1150px;
  top: 20px;
  opacity: 0.2;
  border: 1px solid #000000;
  transform: rotate(90deg);
}

.thead-text p {
  font-size: 16px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: #9B9DA4;
}

.td-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  color: #4A4C51;
  width: 100%;
}

.image-as-title{
  display: flex;
}

.content-vertical-center {
  vertical-align: middle;
}

.td-textChangeStatus {
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #9B9DA4;
}

.label-alert-inapp-status{
  border-radius: 8px;
  margin-top: 25px;
  margin-bottom: 10px;
  background-color: rgb(255, 223, 221);
  color: red !important;
  padding: 5px 10px;
  font-size: 14px;
}

.input-group-custom {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.form-control-custom {
  flex-grow: 1;
  width: 300px;
  height: 40px;
}

.form-control-custom2 {

  width: 150px;
  height: 40px;

}

.form-select-custom {
  width: 120px;
  /* กำหนดความยาวของ Form.Select */

}

.div-row-col {
  /* padding-left: 500px; */
  padding-bottom: 20px;

}


.link-custom {
  margin-left: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #ccc;
  color: #000;
}

.buttou-Search {
  background: var(--color-main-theme);
  border-radius: 22px;
  width: 133px;
  height: 40px;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
}


/* ====================== */

/* AccountSettings */

.nav-tabs .nav-link.active {
  background-color: var(--color-main-theme);
  color: #ffffff;
  border-color: var(--color-main-theme);
  border-radius: 22px;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;


  color: #FFFFFF;
}

/* กำหนดสีของ active tab เมื่อ hover */
.nav-tabs .nav-link {
  background-color: #E5E5E5;
  color: #707070;
  border-color: #E5E5E5;
  border-radius: 22px;
  text-decoration: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;

}

.nav-tabs .nav-link.active,
.nav-tabs .nav-link.active:hover {
  background-color: var(--color-main-theme);
  color: #F3F3F3;
  border-color: var(--color-main-theme);
  border-radius: 22px;
  text-decoration: none;
  /* เพิ่มบรรทัดนี้ */
}

.tabs-box {
  background-color: #E5E5E5;
  border-width: 2px;
  color: #707070;
  border-radius: 22px;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  text-decoration: none;
  margin-bottom: 30px;
}

.card-box {
  --bs-card-spacer-x: 2rem;
}

.card-box-top-pading {
  --bs-card-spacer-y: 1.5rem;
}


.card-box Label {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #454A54;
}

/* ================= */


.list-group-danger .list-group-item {
  background-color: #f8d7da;
  color: #721c24;
  border: 0px;
}


/* Financial */
.col-md-option {
  width: 6%;
}

.div-year {
  padding-bottom: 10px;
}

.chart-option-title {
  font-size: 16px;
  line-height: 27px;
  color: #454A54;
}

.subtitle-box {
  padding-top: 10px;
}

.subtitle-text {
  font-size: 17px;
  line-height: 27px;
  color: #454A54;
}

.subtitle-text-right-align {
  font-size: 17px;
  line-height: 27px;
  text-align: center;
  display: flex;
  color: #454A54;
}

.context-text-p {
  font-size: 14px;
  line-height: 22px;
  color: #454A54;
}

.context-text-p2 {
  font-size: 14px;
  line-height: 22px;
  display: flex;
  text-align: left;
  /* padding-left: 200px; */
  float: right;
  color: #454A54;
  padding-top: 10px;
}


.col-6-div {
  padding-top: 40px;
}

.context-text-price {
  font-size: 24px;
  font-weight: 700;
  line-height: 38px;
  padding-top: 10px;
  color: #454A54;

}

.chart-container {
  margin-top: 20px;
  position: relative;
  width: 100%;
}

.chart-content {
  width: 100%;
  height: 500px;
}

.no-data-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 1.0); /* Adjust the background color and opacity to your preference */
  z-index: 1; /* Ensure the no-data-content div is on top of the chart-content div */
}

.search-no-result {
  color: var(--light-gray-color);
}

.date-time-input div{
  border-radius: 6px !important;
}

.title-content-text{
  cursor: pointer;
}

.title-content-text:hover{
  color: var(--color-main-theme);
  text-decoration: underline;
}

.text-year {
  font-size: 18px;
  line-height: 29px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-top: 3px;
  color: #454A54;
}

.react-tagsinput {
  border-radius: 6px;
}

.react-tagsinput-tag {
  border-radius: 5px !important;
  display: inline !important;
  font-weight: bold !important;
  background-color: rgb(229, 251, 245) !important;
  color: rgb(59, 186, 138) !important;
  padding-top: 2px !important;
  padding-bottom: 3px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  border: 0px !important;
}

.react-tagsinput-remove {
  color: rgb(255, 255, 255);
  padding-top: 2px;
}

.react-tagsinput-input {
  width: 100% !important;
  /* border: 1px solid #ced4da !important;
  border-radius: 6px; */
}

.react-tagsinput-remove a:link {
  text-decoration: none !important;
  color: rgb(255, 255, 255) !important;
}

.react-tagsinput-remove a:link::before {
  text-decoration: none !important;
  color: rgb(255, 255, 255) !important;
}

.input-tag-custom .react-tagsinput-tag {
  border-radius: 25px !important;
  display: inline !important;
  font-weight: bold !important;
  color: rgb(229, 251, 245) !important;
  background-color: rgb(59, 186, 138) !important;
  padding-top: 2px !important;
  padding-bottom: 3px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.input-keyword-custom .react-tagsinput-tag {
  color: rgb(234, 245, 255) !important;
  background-color: rgb(13, 129, 231) !important;
}

.active>.page-link,
.page-link.active {
  background-color: var(--color-main-theme) !important;
  border-color: var(--color-main-theme);
  color: #ffffff !important;
  font-weight: bold !important;
}

.page-link {
  color: var(--color-main-theme) !important;
  font-weight: 600 !important;
}

.form-check-input {
  margin-top: 8px !important;
}

/* .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background: var(--color-main-theme);
  border-radius: 22px;

} */

.menu-nav-item {
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding-left: 20px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  color: #717580;
  border-radius: 25px;
}

.menu-nav-item:hover {
  color: #717580;
}

.menu-nav-item.active {
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding-left: 20px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  color: #ffffff;
  border-radius: 20px;
  background-color: var(--color-main-theme);
}

.menu-nav-item.active .icon-nav {
  filter: brightness(0) invert(1);
}

.loading-container-show {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10000;
  background: rgba(255, 255, 255, 0.5);
  display: block;
  opacity: 1;
  transition: opacity 0.5s;
}

.loading-container-show div {
  position: relative;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
}

.loading-container-hide {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10000;
  background: rgba(255, 255, 255, 0.5);
  display: none;
  opacity: 0;
  transition: opacity 0.5s;
}

.loading-container-hide div {
  position: relative;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
}

 /* date picker */
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px !important;
}


@media (max-width: 1024px) {
  .col-siz {
    /* เมื่อหน้าจอขนาดน้อยกว่า 1024 ให้ซ่อน menu left */
    /* display: none; */
    width: 20%;
  }

  .icon-nav {
    display: none;
  }

  /* date picker */
  .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 10px !important;
  }

  .nav-pills .nav-link.active .icon-nav {
    display: none;
  }

}

@media (min-width: 1025px) {

  .login-content input {
    width: 500px;
  }

  .login-content button {
    width: 500px;
  }

}

.book-overview-page {
  width: 45px;
  height: 70px;
  padding-top: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.book-overview-page-Shelf {
  width: 30px;
  height: 40px;
  padding-top: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.book-overview{
  width: 250px;
}




.fixed-width-column {
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  /* text-align: center; */
}

.card-Modal{
  background: var(--surface-card);
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}


/* Remove the default icon */
.p-checkbox .p-checkbox-box .p-checkbox-icon {
  display: none !important;
}

.p-checkbox input[type="checkbox"] {
  position: absolute !important;
  transform: translate(0, -50%) !important;
  width: 20px;
  height: 20px;
  appearance: none; /* Remove default appearance */
  background-color: white; /* Set the background color */
  border: 1px solid #ccc; /* Add a border */
  border-radius: 3px; /* Add rounded corners */
  cursor: pointer; /* Add a pointer cursor on hover */
}

.p-checkbox input[type="checkbox"]:checked {
  background-color: var(--color-main-theme); /* Set the background color when checked */
  border: 1px solid var(--color-main-theme); /* Match the border color when checked */
}

.p-checkbox input[type="checkbox"]:checked::before {
  content: '\2714'; /* Unicode for checkmark */
  color: white; /* Set the checkmark color to white */
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); /* Center the checkmark */
  font-size: 17px; /* Set the size of the checkmark */
}

.th-Text{
  margin: 0;
  cursor: pointer;
}

.th-Text span {
  margin-left: 5px; /* เพิ่มระยะห่าง 2 พิกเซล */
}
