.react-datepicker {
    font-family: 'Arial', sans-serif;
  }
  
  .react-datepicker-wrapper {
    width: 100%;
  }
  
  .react-datepicker__input-container {
    width: 100%;
  }
  
  .react-datepicker__input-container input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px; /* ปรับแต่งขอบโค้งตามต้องการ */
    box-sizing: border-box;
  }
  
  .react-datepicker-popper {
    z-index: 1;
  }